import {DateTime, Interval} from 'luxon';
import isNumber from 'lodash-es/isNumber';
import isString from 'lodash-es/isString';
import isUndefined from 'lodash-es/isUndefined';
import {IMsDate, MsDateOptions, MsDurationUnit, MsDurationUnits} from './declarations';
import {MsDateSettings} from './settings';

export class LuxonDateImpl implements IMsDate {
  parseDate: DateTime;
  inputValue: string;

  private readonly dateFormat = 'MM/dd/yyyy';
  private readonly dateFormatMonthYear = 'MM/yyyy';
  private readonly dateFormatIso = 'yyyy-MM-dd';
  private readonly dateFormatFullMonthYear = 'MMMM yyyy';
  private readonly minimumPastDate = '1900-01-01';
  //   private dateTimeFormatUTC = 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'';
  //   private fateTimeFormatUTCMidnight = 'yyyy-MM-dd\'T\'00:00:00\'Z\'';

  private readonly _isValid: boolean;

  get isValid() {
    return this._isValid;
  }

  get year() {
    return this.parseDate.year;
  }

  get month() {
    return this.parseDate.month;
  }

  get day() {
    return this.parseDate.day;
  }

  get hour() {
    return this.parseDate.hour;
  }

  get minute() {
    return this.parseDate.minute;
  }

  constructor(val?: any, options?: MsDateOptions) {
    this.inputValue = val;
    this.parseDate = this.parseInput(val, options);
    this._isValid =
      this.parseDate && this.parseDate.isValid && this.parseDate >= DateTime.fromISO(this.minimumPastDate);
  }

  private static getCurrentDate(zone: string): DateTime {
    return zone ? DateTime.now().setZone(zone) : DateTime.now();
  }

  private static parseLuxonDateImpl(val: LuxonDateImpl, zone: string): DateTime {
    return zone ? val.parseDate.setZone(zone) : val.parseDate;
  }

  private static parseDateTime(val: DateTime, zone: string): DateTime {
    return zone ? val.setZone(zone) : val;
  }

  private parseInput(val: any, options?: MsDateOptions): DateTime {
    const zone = options && options.zone ? options.zone : null;
    const format = options && options.format;
    const dateTimeZoneOptions = {zone: zone || MsDateSettings.defaultZone};

    if (format) {
      return DateTime.fromFormat(val, format);
    }

    if (isUndefined(val)) {
      return LuxonDateImpl.getCurrentDate(zone);
    }

    if (val instanceof Date) {
      return DateTime.fromJSDate(val, dateTimeZoneOptions);
    }

    if (val instanceof LuxonDateImpl) {
      return LuxonDateImpl.parseLuxonDateImpl(val, zone);
    }

    if (val instanceof DateTime) {
      return LuxonDateImpl.parseDateTime(val, zone);
    }

    if (isString(val)) {
      return this.parseInput(new Date(val), options);
    }

    if (isNumber(val)) {
      return DateTime.fromMillis(val, dateTimeZoneOptions);
    }

    return null;
  }

  set(duration: MsDurationUnits): IMsDate {
    return new LuxonDateImpl(this.parseDate.set(duration));
  }

  plus(duration: MsDurationUnits): IMsDate {
    return new LuxonDateImpl(this.parseDate.plus(duration));
  }

  minus(duration: MsDurationUnits): IMsDate {
    return new LuxonDateImpl(this.parseDate.minus(duration));
  }

  diff(val: any, unit: MsDurationUnit = 'millisecond'): number {
    return Math.floor(this.parseInput(val).diff(this.parseDate, unit)[`${unit}s`]);
  }

  startOf(unit: MsDurationUnit): IMsDate {
    if (unit === 'week') {
      return new LuxonDateImpl(this.parseDate.plus({days: 1}).startOf(unit).minus({days: 1}));
    }
    return new LuxonDateImpl(this.parseDate.startOf(unit));
  }

  endOf(unit: MsDurationUnit): IMsDate {
    if (unit === 'week') {
      return new LuxonDateImpl(this.parseDate.endOf(unit).minus({days: 1}));
    }
    return new LuxonDateImpl(this.parseDate.endOf(unit));
  }

  locale(): string {
    return this.parseDate.locale;
  }

  toUTC(): IMsDate {
    return new LuxonDateImpl(this.parseDate.toUTC(), {zone: 'utc'});
  }

  toDateTimeISO(): string {
    return this.parseDate.toISO();
  }

  toFormat(formatValue: string): string {
    return this.parseDate.toFormat(formatValue);
  }

  toCST(formatValue = 'MMM dd yyyy HH:mm A'): string {
    return this.parseDate.setZone('America/Chicago').toFormat(formatValue);
  }

  toDate(): string {
    return this.parseDate.toFormat(this.dateFormat);
  }

  toISO(): string {
    return this.parseDate.toFormat(this.dateFormatIso);
  }

  toMonthYear(): string {
    return this.parseDate.toFormat(this.dateFormatMonthYear);
  }

  toFullMonthYear(): string {
    return this.parseDate.toFormat(this.dateFormatFullMonthYear);
  }

  isToday(): boolean {
    return DateTime.now().hasSame(this.parseDate, 'day');
  }

  isPast(unit: MsDurationUnit = 'millisecond'): boolean {
    return this.parseDate.startOf(unit) < DateTime.now().startOf(unit);
  }

  isFuture(unit: MsDurationUnit = 'millisecond'): boolean {
    return this.parseDate.startOf(unit) > DateTime.now().startOf(unit);
  }

  isBefore(val: any, unit: MsDurationUnit = 'millisecond'): boolean {
    return this.parseDate.startOf(unit) < this.parseInput(val).startOf(unit);
  }

  isSame(val: any, unit: MsDurationUnit = 'millisecond'): boolean {
    const interval = Interval.fromDateTimes(this.parseDate, this.parseInput(val));
    return interval.hasSame(unit);
  }

  isSameOrBefore(val: any, unit: MsDurationUnit = 'millisecond'): boolean {
    return this.parseDate.startOf(unit) <= this.parseInput(val).startOf(unit);
  }

  isAfter(val: any, unit: MsDurationUnit = 'millisecond'): boolean {
    return this.parseDate.startOf(unit) > this.parseInput(val).startOf(unit);
  }

  isSameOrAfter(val: any, unit: MsDurationUnit = 'millisecond'): boolean {
    return this.parseDate.startOf(unit) >= this.parseInput(val).startOf(unit);
  }
}
