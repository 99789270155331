export abstract class MsDateSettings {
  private static _defaultZone = 'system';

  static set defaultZone(zone) {
    this._defaultZone = zone || 'system';
  }

  static get defaultZone() {
    return this._defaultZone;
  }
}
